@import "../../sass/variables";

.title-docs {
  color: $btnColorSecondary;
  font-size: 1.5rem;
}
.list-container {
  margin-top: 1rem;
  color: $shadowInfoCounter;
  .anchor-doc {
    color: $shadowInfoCounter;
    &:link {
      color: $shadowInfoCounter;
      text-decoration: none;
    }
    &:visited {
      color: $shadowInfoCounter;
    }
    &:active {
      color: $shadowInfoCounter;
    }
  }
}
