/*Colors*/
/*Colors Opacity*/
/*Global*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.ene-section-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  height: 100vh;
  scroll-snap-align: start;
  scroll-snap-stop: always;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  position: relative;
}
.ene-section-container .ene-bg {
  width: 100%;
  height: 100%;
}
.ene-section-container .ene-btn-container {
  position: absolute;
  bottom: 20%;
}
.ene-section-container .ene-btn-container .ene-btn {
  border: 1px solid #ffffff;
  border-radius: 1rem;
  background-color: #ffffff;
  color: #3b3e43;
  padding: 0.5rem 2.5rem;
  font-size: 1.2rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ene-section-container .ene-btn-container .ene-btn:link {
  color: #3b3e43;
  text-decoration: none;
}
.ene-section-container .ene-btn-container .ene-btn:visited {
  color: #3b3e43;
}
.ene-section-container .ene-btn-container .ene-btn:active {
  color: #3b3e43;
}
.ene-section-container .ene-btn-container .ene-btn:hover {
  background-color: #3b3e43;
  color: #ffffff;
}

/*Responsive Mobile Small*/
@media screen and (min-width: 320px) and (max-width: 479px) {
  .ene-section-container {
    overflow: hidden;
  }
  .ene-section-container .ene-bg {
    width: 65rem !important;
  }
  .ene-section-container .ene-btn-container {
    margin: 0 5%;
  }
  .ene-section-container .ene-btn-container .ene-btn {
    font-size: 0.8rem;
  }
}
/*Responsive Mobile Standard*/
@media screen and (min-width: 480px) and (max-width: 767px) {
  .ene-section-container {
    overflow: hidden;
  }
  .ene-section-container .ene-bg {
    width: 100rem !important;
  }
  .ene-section-container .ene-btn {
    bottom: 5rem;
  }
}
/*Responsive Tablet Small*/
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .ene-section-container {
    overflow: hidden;
  }
  .ene-section-container .ene-bg {
    width: 114rem !important;
  }
}/*# sourceMappingURL=EnergySection.css.map */