/*Colors*/
/*Colors Opacity*/
/*Global*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.job-offer-info-section {
  width: 100%;
  display: flex;
  justify-content: center;
}
.job-offer-info-section .job-offer-info-subcontainer {
  width: 1024px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 4rem 0;
  row-gap: 2rem;
}
.job-offer-info-section .job-offer-info-subcontainer .joi-skill-container {
  width: 65%;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  color: #2c2c2c;
}
.job-offer-info-section .job-offer-info-subcontainer .joi-job-info-container {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
.job-offer-info-section .job-offer-info-subcontainer .joi-ji-grid {
  display: flex;
  align-items: center;
  color: #2c2c2c;
  -moz-column-gap: 5rem;
       column-gap: 5rem;
}
.job-offer-info-section .job-offer-info-subcontainer .joi-skill-title {
  color: #2c2c2c;
  font-weight: 500;
  font-size: 2rem;
}
.job-offer-info-section .job-offer-info-subcontainer .joi-skill-elm-container {
  display: flex;
  align-items: center;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
  flex-wrap: wrap;
}
.job-offer-info-section .job-offer-info-subcontainer .joi-se-elm {
  color: #2c2c2c;
  border: 1px solid #2c2c2c;
  font-size: 1rem;
  border-radius: 0.5rem;
  padding: 0.25rem 1rem;
  text-align: center;
  font-weight: 400;
}
.job-offer-info-section .job-offer-info-subcontainer .joi-description-container {
  width: 65%;
  color: #2c2c2c;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
.job-offer-info-section .job-offer-info-subcontainer .joi-func-container {
  width: 65%;
  color: #2c2c2c;
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}
.job-offer-info-section .job-offer-info-subcontainer .joi-benefits-container {
  width: 65%;
  color: #2c2c2c;
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}
.job-offer-info-section .job-offer-info-subcontainer .joi-requirements-container {
  width: 65%;
  color: #2c2c2c;
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}
.job-offer-info-section .job-offer-info-subcontainer .joi-skills-container {
  width: 65%;
  color: #2c2c2c;
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}

@media screen and (min-width: 320px) and (max-width: 479px) {
  .job-offer-info-subcontainer {
    width: 90% !important;
    align-items: center !important;
  }
  .job-offer-info-subcontainer .joi-skill-elm-container {
    row-gap: 1rem;
  }
}
@media screen and (min-width: 480px) and (max-width: 767px) {
  .job-offer-info-subcontainer {
    width: 80% !important;
    align-items: center !important;
  }
  .job-offer-info-subcontainer .joi-skill-elm-container {
    row-gap: 1rem;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .job-offer-info-subcontainer {
    width: 90% !important;
    align-items: center !important;
  }
  .job-offer-info-subcontainer .joi-skill-elm-container {
    row-gap: 1rem;
  }
}/*# sourceMappingURL=JobOfferInfoSection.css.map */