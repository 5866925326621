@import '../../sass/variables';

.counter-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .nums-container {
        display: flex;
        color: $basicColorPrimary;
        font-size: 1.8rem;
    }
    .counter-subtitle {
        color: $basicColorPrimary;
        font-size: 1.1rem;
        text-align: center;
    }
    .show-sign {
        display: unset;
    }
    .hide-sign {
        display: none;
    }
    .add-space {
        margin-left: 1rem;
    }
}

/*Responsive Mobile Small*/

@media screen and (min-width: $mobileSm) and (max-width: calc($mobileStandard - 1px)) {
    .nums-container {
        font-size: 1rem !important;
    }
    .counter-subtitle {
        font-size: 0.5rem !important;
    }
  }

/*Responsive Mobile Standard*/

@media screen and (min-width: $mobileStandard) and (max-width: calc($tabletSm - 1px)) {
    .nums-container {
        font-size: 1.1rem !important;
    }
    .counter-subtitle {
        font-size: 0.6rem !important;
    }
  }