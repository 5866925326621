@import "../../sass/variables";

.waw-section {
  height: 100%;
  .waw-title-container {
    padding-top: 3rem;
    width: 50%;
    display: flex;
    color: rgb(78, 78, 78);
    align-items: center;
    column-gap: 0.5rem;
  }
  .deco-title {
    width: 1.5rem;
    height: 1rem;
    border: 1px solid grey;
    background-color: grey;
    border-radius: 31px 0px 0px 32px;
  }
  .deco-oposite {
    border: 1px solid $basicColorPrimary;
    background-color: $basicColorPrimary;
  }
  .second-waw-title {
    color: $basicColorPrimary;
  }
  .half-section-container {
    height: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .first {
    background-image: url("https://pagina-otacc.s3.amazonaws.com/pages/otacc-page/aboutus/assets/section-first-bg-1.webp");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding-bottom: 3rem;
  }
  .second {
    background-image: url("https://pagina-otacc.s3.amazonaws.com/pages/otacc-page/aboutus/assets/section-first-bg-2.webp");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .info-first-half-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 2rem;
    width: 50%;
    column-gap: 1rem;
    .info-fh-elm-container {
      display: flex;
      width: 25rem;
      column-gap: 1rem;
    }
    .title-icon {
      width: 2rem;
    }
    .num-container {
      font-size: 2rem;
      color: $basicColorPrimary;
    }
    .text-info-elm-container {
      display: flex;
      flex-direction: column;
      color: rgb(78, 78, 78);
    }
    .title-fh-elm-container {
      display: flex;
      align-items: center;
      color: rgb(78, 78, 78);
      column-gap: 2rem;
    }
  }
  .waw-sh-info-container {
    display: flex;
    flex-direction: column;
    color: $basicColorPrimary;
    align-items: center;
    width: 50%;
    margin-top: 3rem;
    row-gap: 3.5rem;
    margin-bottom: 3rem;
    .subtitle-waw-sh {
      align-self: flex-start;
      color: $basicColorPrimary;
    }
  }
  .info-second-half-container {
    height: 50%;
    display: flex;
    justify-content: center;
    column-gap: 1.5rem;
    .info-sh-subcontainer {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      row-gap: 2rem;
      color: $basicColorPrimary;
    }
    .info-sh-icon {
      width: 5rem;
      height: 5rem;
    }
  }
}

//Responsive Mobile Small
@media screen and (min-width: $mobileSm) and (max-width: calc($mobileStandard - 1px)) {
  .waw-section {
    height: unset;
    .first {
      padding-bottom: 3rem;
      background-position-x: center;
      background-size: cover;
    }
    .second {
      padding-bottom: 3rem;
      background-position-x: center;
      background-size: cover;
    }
    .waw-title-container {
      width: unset;
    }
    .info-first-half-container {
      flex-direction: column;
      width: 90%;
      row-gap: 1.5rem;
      .info-fh-elm-container {
        width: unset;
      }
      .title-icon {
        display: none;
      }
    }
    .num-icon {
      width: 3rem;
    }
    .waw-sh-info-container {
      width: 90%;
      row-gap: 2rem;
      .info-second-half-container {
        flex-direction: column;
        row-gap: 1.5rem;
      }
    }
    .info-sh-subcontainer {
      text-align: center;
    }
  }
}

//Responsive Mobile Standard
@media screen and (min-width: $mobileStandard) and (max-width: calc($tabletSm - 1px)) {
  .waw-section {
    height: unset;
    .first {
      padding-bottom: 3rem;
      background-position-x: center;
      background-size: cover;
    }
    .second {
      padding-bottom: 3rem;
      background-position-x: center;
      background-size: cover;
    }
    .waw-title-container {
      width: unset;
    }
    .info-first-half-container {
      flex-direction: column;
      width: 80%;
      row-gap: 1.5rem;
      .info-fh-elm-container {
        width: unset;
      }
    }
    .num-icon {
      width: 3rem;
    }
    .waw-sh-info-container {
      width: 80%;
      row-gap: 2rem;
      .info-second-half-container {
        flex-direction: column;
        row-gap: 1.5rem;
      }
    }
    .info-sh-subcontainer {
      text-align: center;
    }
  }
}

//Responsive Tablet Small
@media screen and (min-width: $tabletSm) and (max-width: calc($tabletStandard - 1px)) {
  .waw-section {
    height: unset;
    .first {
      padding-bottom: 3rem;
      background-position-x: center;
      background-position-y: bottom;
      background-size: 125rem;
    }
    .second {
      padding-bottom: 3rem;
      background-position-x: center;
      background-size: 125rem;
    }
    .waw-title-container {
      width: unset;
    }
    .info-first-half-container {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      width: 90%;
      .info-fh-elm-container {
        width: unset;
      }
      .title-icon {
        display: none;
      }
    }
    .num-icon {
      width: 3rem;
    }
    .waw-sh-info-container {
      width: 90%;
    }
  }
}

//Responsive Tablet Standard
@media screen and (min-width: $tabletStandard) and (max-width: calc($screenSm - 1px)) {
  .waw-section {
    height: unset;
    .first {
      padding-bottom: 3rem;
      background-position-x: center;
      background-position-y: bottom;
      background-size: 100rem;
    }
    .second {
      padding-bottom: 3rem;
      background-position-x: center;
      background-size: 100rem;
    }
    .waw-title-container {
      width: unset;
    }
    .info-first-half-container {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      width: 90%;
      .info-fh-elm-container {
        width: unset;
      }
      .title-icon {
        display: none;
      }
    }
    .num-icon {
      width: 3rem;
    }
    .waw-sh-info-container {
      width: 90%;
    }
  }
}

//Responsive Screen Small
@media screen and (min-width: $screenSm) and (max-width: calc($screenHd - 1px)) {
  .waw-section {
    .first {
      padding-bottom: 6rem;
      background-position-x: center;
      background-position-y: bottom;
      background-size: cover;
    }
    .second {
      background-position-x: center;
      background-size: cover;
    }
    .waw-title-container {
      width: 100%;
      margin-left: 10%;
    }
    .info-first-half-container {
      width: 90%;
    }
    .waw-sh-info-container {
      width: 90%;
      .info-sh-subcontainer {
        margin-bottom: 3rem;
      }
    }
  }
}
