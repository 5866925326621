/*Colors*/
/*Colors Opacity*/
/*Global*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.footer-container {
  border-top: 1px solid #000000;
  padding: 4rem 5%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -moz-column-gap: 2rem;
       column-gap: 2rem;
}
.footer-container h3 {
  margin-bottom: 1rem;
}
.footer-container .social-media-footer {
  margin-top: 1rem;
}
.footer-container .social-media-footer .social-media-container {
  display: flex;
  -moz-column-gap: 0.5rem;
       column-gap: 0.5rem;
}
.footer-container .social-media {
  width: 2rem;
  height: 2rem;
}
.footer-container .footer-info-container {
  display: flex;
  flex-direction: column;
}
.footer-container .doc-anchor {
  color: #000000;
}
.footer-container .doc-anchor:link {
  color: #000000;
  text-decoration: none;
}
.footer-container .doc-anchor:visited {
  color: #000000;
}
.footer-container .doc-anchor:active {
  color: #000000;
}
.footer-container .foundation-btn-container {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
  justify-content: space-between;
}
.footer-container .foundation-btn-container .anchor-foundation {
  border: 2px solid #000000;
  border-radius: 0.5rem;
  padding: 0.2rem 1rem 0.2rem 1rem;
  display: flex;
  align-items: center;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
  height: 2rem;
  color: #000000;
}
.footer-container .foundation-btn-container .anchor-foundation:link {
  color: #000000;
  text-decoration: none;
}
.footer-container .foundation-btn-container .anchor-foundation:visited {
  color: #000000;
}
.footer-container .foundation-btn-container .anchor-foundation:active {
  color: #000000;
}
.footer-container .foundation-btn-container .anchor-foundation:hover {
  background-color: #000000;
  color: #ffffff;
}
.footer-container .foundation-btn-container .anchor-foundation:hover .separation-line-btn {
  border-left: 1px solid #ffffff;
}
.footer-container .foundation-btn-container .separation-line-btn {
  height: 2.2rem;
  border-left: 1px solid #000000;
}
.footer-container .foundation-btn-container .icon-btn-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer-container .foundation-btn-container .btn-icon {
  width: 2rem;
}

/*Responsive Mobile Small*/
@media screen and (min-width: 320px) and (max-width: 767px) {
  .footer-container {
    grid-template-columns: 1fr;
    text-align: center;
    row-gap: 1rem;
  }
  .footer-container .footer-info-container {
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .footer-container .social-media-container {
    justify-content: center;
  }
}
/*Responsive Mobile Standard*/
@media screen and (min-width: 480px) and (max-width: 767px) {
  .footer-container {
    grid-template-columns: 1fr;
    text-align: center;
    row-gap: 1rem;
  }
  .footer-container .footer-info-container {
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .footer-container .social-media-container {
    justify-content: center;
  }
}
/*Responsive Tablet Small*/
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .footer-container {
    grid-template-columns: 1fr;
    text-align: center;
    row-gap: 1rem;
    padding: 4rem 10%;
  }
  .footer-container .footer-info-container {
    font-size: unset;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .footer-container .social-media-container {
    justify-content: center;
  }
}/*# sourceMappingURL=FooterPropDevs.css.map */