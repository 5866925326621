/*Colors*/
/*Colors Opacity*/
/*Global*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.pd-counter-section {
  width: 100%;
  max-height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  overflow: hidden;
}
.pd-counter-section .pd-counter-video {
  transform: scale(1.5);
  height: 100vh;
}
.pd-counter-section .pd-counter-img {
  transform: scale(1.3);
}
.pd-counter-section .pd-counters-container {
  display: flex;
  position: absolute;
  bottom: 2rem;
  -moz-column-gap: 2rem;
       column-gap: 2rem;
  flex-wrap: wrap;
  justify-content: center;
}

/*Responsive Mobile Small*/
@media screen and (min-width: 320px) and (max-width: 479px) {
  .pd-counter-section {
    overflow: hidden;
  }
  .pd-counter-section .pd-counter-img {
    width: 57rem;
  }
  .pd-counter-section .pd-counters-container {
    margin: 0 0.5rem;
    -moz-column-gap: 1rem;
         column-gap: 1rem;
  }
  .pd-counter-section .info-pd-counter-container {
    width: 70%;
  }
  .pd-counter-section .info-counter-list {
    flex-direction: column;
  }
}
/*Responsive Mobile Standard*/
@media screen and (min-width: 480px) and (max-width: 767px) {
  .pd-counter-section {
    overflow: hidden;
  }
  .pd-counter-section .pd-counter-img {
    width: 99rem;
  }
  .pd-counter-section .pd-counters-container {
    margin: 0 0.5rem;
    -moz-column-gap: 1rem;
         column-gap: 1rem;
  }
  .pd-counter-section .info-pd-counter-container {
    width: 70%;
  }
}
/*Responsive Tablet Small*/
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .pd-counter-section {
    overflow: hidden;
  }
  .pd-counter-section .pd-counter-img {
    width: 120rem;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1919px) {
  .pd-counter-img {
    transform: unset !important;
    width: 100% !important;
  }
}/*# sourceMappingURL=PDCounterSection.css.map */