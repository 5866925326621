/*Colors*/
/*Colors Opacity*/
/*Global*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.otacc-gallery-container {
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7725490196);
  z-index: 99;
  display: none;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.otacc-gallery-container .close-btn-container {
  display: flex;
  justify-content: flex-end;
  align-self: flex-end;
  margin-right: 2%;
}
.otacc-gallery-container .close-btn-container .close-icon {
  width: 1rem;
  color: #ffffff;
  font-size: 1.5rem;
  cursor: pointer;
}
.otacc-gallery-container .imgs-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  -moz-column-gap: 1.5rem;
       column-gap: 1.5rem;
  width: 100%;
  margin: 0 5%;
}
.otacc-gallery-container .imgs-container .icon-gallery {
  color: #ffffff;
  font-size: 2rem;
  cursor: pointer;
  margin: 0 10%;
}
.otacc-gallery-container .imgs-container .img-container {
  height: 40rem;
  overflow: hidden;
}
.otacc-gallery-container .imgs-container .img-gallery {
  height: 100%;
  width: 100%;
}

.open {
  display: flex;
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .icon-gallery {
    display: none;
  }
  .img-container {
    max-height: 24rem !important;
    height: unset !important;
  }
  .imgs-container {
    flex-direction: column;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1199px) {
  .img-container {
    max-height: 30rem !important;
    height: unset !important;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1919px) {
  .icon-gallery {
    margin: 0 5% !important;
  }
}/*# sourceMappingURL=GalleryEnergy.css.map */