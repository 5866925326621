@import "../../sass/variables";

.nb-prop-devs-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  position: fixed;
  z-index: 999;
  .nb-prop-devs-logo-container {
    margin-left: 3%;
    .nb-prop-devs-logo-img {
      width: 9rem;
      height: 100%;
    }
  }
  .nb-prop-devs-menus-container-nm {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-right: 5%;
    border-radius: 0.5rem;
    border: 1px solid darken($navbarBgColorOpa, $amount: 20);
    background-color: darken($navbarBgColorOpa, $amount: 20);
    .nb-prop-devs-option {
      margin: 0 2rem;
      color: $basicColorPrimary;
      &:link {
        color: $basicColorPrimary;
        text-decoration: none;
      }
      &:visited {
        color: $basicColorPrimary;
      }
      &:active {
        color: $basicColorPrimary;
      }
    }
    .text-darken {
      color: $basicColorSecondary;
      &:link {
        color: $basicColorSecondary;
      }
      &:visited {
        color: $basicColorSecondary;
      }
      &:active {
        color: $basicColorSecondary;
      }
    }
    .current {
      border: 1px solid $navbarSelectedColor;
      background-color: $navbarSelectedColor;
      padding: 0.3rem 1rem;
      border-radius: 0.5rem;
      margin: 0;
    }
  }
  .nb-prop-devs-mobile-container {
    display: none;
    margin-right: 5%;
    .ham-icon {
      width: 1rem;
      height: 1rem;
      cursor: pointer;
    }
    .mb-prop-devs-menus-container {
      width: 100%;
      background-color: $navbarBgColorOpa;
      z-index: 2;
      position: fixed;
      top: 5rem;
      left: 0;
      padding: 1rem 2rem;
      transition: all 2s;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
    .nb-prop-devs-option {
      margin: 0 3rem 1rem 0;
      color: $basicColorSecondary;
      &:link {
        color: $basicColorSecondary;
        text-decoration: none;
      }
      &:visited {
        color: $basicColorSecondary;
      }
      &:active {
        color: $basicColorSecondary;
      }
    }
    .closed-menu {
      @keyframes close {
        0% {
          left: 0rem;
        }
        100% {
          left: 65rem;
        }
      }
      animation-name: close;
      animation-duration: 1.5s;
      left: 65rem;
    }
  }
}

/*Responsive Mobile Small - Tablet Standard*/

@media screen and (min-width: $mobileSm) and (max-width: calc($tabletStandard - 1px)) {
  .nb-prop-devs-logo-img {
    width: 6rem !important;
  }
  .nb-prop-devs-menus-container-nm {
    display: none !important;
  }
  .nb-prop-devs-mobile-container {
    display: unset !important;
  }
}
