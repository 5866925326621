@import "../../sass/variables";

.gallery-container {
  display: flex;
  align-items: center;
  z-index: 2;
  .img-container {
    position: relative;
    .gallery-img {
      border-radius: 0.5rem;
      margin-right: 1rem;
      height: 18rem;
      width: 30rem;
    }
  }
  .thumbs-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 18.5rem;
    column-gap: 1rem;
    row-gap: 1rem;
    padding-top: 0.3rem;
    .thumb {
      cursor: pointer;
    }
    .last {
      position: relative;
    }
    .more-btn-container {
      position: absolute;
      background-color: $cardHoverOpa;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      border-radius: 0.5rem;
    }
    .img-total {
      color: $basicColorPrimary;
      font-size: 1.3rem;
      margin-bottom: 0.2rem;
    }
    .more-btn {
      width: 70%;
      height: 2rem;
      cursor: pointer;
      background-color: $btnColorPrimary;
      border: $btnColorPrimary;
      border-radius: 0.5rem;
      color: $basicColorPrimary;
      &:hover {
        background-color: $btnColorPrimaryHover;
      }
      &:active {
        background-color: $btnColorPrimaryHover;
        background-size: 100%;
        transition: background 0s;
      }
    }
    .thumb-img {
      width: 15rem;
      height: 8.5rem;
      border-radius: 0.5rem;
    }
  }
}

/*Responsive Tablet Small*/

@media screen and (min-width: $tabletSm) and (max-width: calc($tabletStandard - 1px)) {
  .thumbs-container {
    grid-template-columns: 1fr !important;
  }
}
