/*Colors*/
/*Colors Opacity*/
/*Global*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.nb-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  position: fixed;
  z-index: 2;
}
.nb-container .nb-logo-container {
  margin-left: 3%;
}
.nb-container .nb-logo-container .nb-logo-img {
  width: 9rem;
  height: 100%;
}
.nb-container .nb-menus-container-nm {
  display: flex;
  align-items: center;
  border: 1px solid rgba(255, 255, 255, 0.7490196078);
  justify-content: space-around;
  margin-right: 5%;
  border-radius: 0.5rem;
  background-color: rgba(255, 255, 255, 0.7490196078);
}
.nb-container .nb-menus-container-nm .nb-option {
  margin: 0 2rem;
  color: #000000;
}
.nb-container .nb-menus-container-nm .nb-option:link {
  color: #000000;
  text-decoration: none;
}
.nb-container .nb-menus-container-nm .nb-option:visited {
  color: #000000;
}
.nb-container .nb-menus-container-nm .nb-option:active {
  color: #000000;
}
.nb-container .nb-menus-container-nm .current {
  border: 1px solid #e1e1e1;
  background-color: #e1e1e1;
  padding: 0.3rem 1rem;
  border-radius: 0.5rem;
  margin: 0;
}
.nb-container .nb-menus-mobile-container {
  display: none;
  margin-right: 5%;
}
.nb-container .nb-menus-mobile-container .ham-icon {
  width: 1rem;
  height: 1rem;
  cursor: pointer;
}
.nb-container .nb-menus-mobile-container .mb-menus-container {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.7490196078);
  z-index: 2;
  position: fixed;
  top: 5rem;
  left: 0;
  padding: 1rem 2rem;
  transition: all 2s;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.nb-container .nb-menus-mobile-container .nb-option {
  margin: 0 3rem 1rem 0;
  color: #000000;
}
.nb-container .nb-menus-mobile-container .nb-option:link {
  color: #000000;
  text-decoration: none;
}
.nb-container .nb-menus-mobile-container .nb-option:visited {
  color: #000000;
}
.nb-container .nb-menus-mobile-container .nb-option:active {
  color: #000000;
}
.nb-container .nb-menus-mobile-container .closed-menu {
  animation-name: close;
  animation-duration: 1.5s;
  left: 65rem;
}
@keyframes close {
  0% {
    left: 0rem;
  }
  100% {
    left: 65rem;
  }
}

/*Responsive Mobile Small - Tablet Standard*/
@media screen and (min-width: 320px) and (max-width: 1023px) {
  .nb-logo-img {
    width: 6rem !important;
  }
  .nb-menus-container-nm {
    display: none !important;
  }
  .nb-menus-mobile-container {
    display: unset !important;
  }
}/*# sourceMappingURL=Navbar.css.map */