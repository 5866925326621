/*Colors*/
/*Colors Opacity*/
/*Global*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.nb-prop-devs-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  position: fixed;
  z-index: 999;
}
.nb-prop-devs-container .nb-prop-devs-logo-container {
  margin-left: 3%;
}
.nb-prop-devs-container .nb-prop-devs-logo-container .nb-prop-devs-logo-img {
  width: 9rem;
  height: 100%;
}
.nb-prop-devs-container .nb-prop-devs-menus-container-nm {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-right: 5%;
  border-radius: 0.5rem;
  border: 1px solid rgba(204, 204, 204, 0.7490196078);
  background-color: rgba(204, 204, 204, 0.7490196078);
}
.nb-prop-devs-container .nb-prop-devs-menus-container-nm .nb-prop-devs-option {
  margin: 0 2rem;
  color: #ffffff;
}
.nb-prop-devs-container .nb-prop-devs-menus-container-nm .nb-prop-devs-option:link {
  color: #ffffff;
  text-decoration: none;
}
.nb-prop-devs-container .nb-prop-devs-menus-container-nm .nb-prop-devs-option:visited {
  color: #ffffff;
}
.nb-prop-devs-container .nb-prop-devs-menus-container-nm .nb-prop-devs-option:active {
  color: #ffffff;
}
.nb-prop-devs-container .nb-prop-devs-menus-container-nm .text-darken {
  color: #000000;
}
.nb-prop-devs-container .nb-prop-devs-menus-container-nm .text-darken:link {
  color: #000000;
}
.nb-prop-devs-container .nb-prop-devs-menus-container-nm .text-darken:visited {
  color: #000000;
}
.nb-prop-devs-container .nb-prop-devs-menus-container-nm .text-darken:active {
  color: #000000;
}
.nb-prop-devs-container .nb-prop-devs-menus-container-nm .current {
  border: 1px solid #e1e1e1;
  background-color: #e1e1e1;
  padding: 0.3rem 1rem;
  border-radius: 0.5rem;
  margin: 0;
}
.nb-prop-devs-container .nb-prop-devs-mobile-container {
  display: none;
  margin-right: 5%;
}
.nb-prop-devs-container .nb-prop-devs-mobile-container .ham-icon {
  width: 1rem;
  height: 1rem;
  cursor: pointer;
}
.nb-prop-devs-container .nb-prop-devs-mobile-container .mb-prop-devs-menus-container {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.7490196078);
  z-index: 2;
  position: fixed;
  top: 5rem;
  left: 0;
  padding: 1rem 2rem;
  transition: all 2s;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.nb-prop-devs-container .nb-prop-devs-mobile-container .nb-prop-devs-option {
  margin: 0 3rem 1rem 0;
  color: #000000;
}
.nb-prop-devs-container .nb-prop-devs-mobile-container .nb-prop-devs-option:link {
  color: #000000;
  text-decoration: none;
}
.nb-prop-devs-container .nb-prop-devs-mobile-container .nb-prop-devs-option:visited {
  color: #000000;
}
.nb-prop-devs-container .nb-prop-devs-mobile-container .nb-prop-devs-option:active {
  color: #000000;
}
.nb-prop-devs-container .nb-prop-devs-mobile-container .closed-menu {
  animation-name: close;
  animation-duration: 1.5s;
  left: 65rem;
}
@keyframes close {
  0% {
    left: 0rem;
  }
  100% {
    left: 65rem;
  }
}

/*Responsive Mobile Small - Tablet Standard*/
@media screen and (min-width: 320px) and (max-width: 1023px) {
  .nb-prop-devs-logo-img {
    width: 6rem !important;
  }
  .nb-prop-devs-menus-container-nm {
    display: none !important;
  }
  .nb-prop-devs-mobile-container {
    display: unset !important;
  }
}/*# sourceMappingURL=NavbarPropDevsSection.css.map */