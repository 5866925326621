/*Colors*/
/*Colors Opacity*/
/*Global*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.clients-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3rem 0;
}
.clients-section .clients-title-container {
  margin: 3rem 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.clients-section .clients-title-container .clients-title {
  margin-bottom: 1rem;
  font-size: 1.8rem;
}
.clients-section .clients-slide-container {
  width: 90%;
}
.clients-section .carousel-no-mobile-clients {
  display: flex;
  justify-content: center;
}
.clients-section .carousel-no-mobile-clients .clients-minor-eight-container {
  display: flex;
  justify-content: space-between;
  width: 60%;
}

/*Responsive Mobile Small*/
@media screen and (min-width: 320px) and (max-width: 479px) {
  .clients-title-container {
    font-size: 1rem !important;
  }
  .carousel-no-mobile-clients {
    display: none !important;
  }
  .clients-minor-eight-container {
    display: none !important;
  }
}
/*Responsive Hide Carousel Mobile*/
@media screen and (min-width: 480px) {
  .carousel-mobile {
    display: none;
  }
}
@media screen and (min-width: 480px) and (max-width: 1023px) {
  .clients-minor-eight-container {
    width: 50% !important;
  }
}/*# sourceMappingURL=PDClientsSlide.css.map */