/*Colors*/
/*Colors Opacity*/
/*Global*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.title-docs {
  color: #00764F;
  font-size: 1.5rem;
}

.list-container {
  margin-top: 1rem;
  color: #ada4a9;
}
.list-container .anchor-doc {
  color: #ada4a9;
}
.list-container .anchor-doc:link {
  color: #ada4a9;
  text-decoration: none;
}
.list-container .anchor-doc:visited {
  color: #ada4a9;
}
.list-container .anchor-doc:active {
  color: #ada4a9;
}/*# sourceMappingURL=DocsByYearComponent.css.map */