/*Colors*/
/*Colors Opacity*/
/*Global*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.not-found-section {
  height: 100vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-image: url("https://pagina-otacc.s3.amazonaws.com/pages/otacc-page/assets/bgs/map-bg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: center;
  width: 100%;
}
.not-found-section .info-err-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 2rem;
  color: #5e5e5e;
}
.not-found-section .info-err-container h1 {
  font-weight: 200;
  font-size: 2.1rem;
  margin-left: 0.5rem;
}
.not-found-section .info-err-container p {
  width: 80%;
  margin-top: 1rem;
  margin-left: 0.5rem;
}
.not-found-section .info-err-container .logo-otacc {
  width: 15rem;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .not-found-section {
    flex-direction: column;
    padding: 10% 0;
    margin: 15% 0;
  }
  .not-found-section .info-err-container {
    align-items: center;
    margin: 0 10%;
  }
  .not-found-section .img-err-container {
    margin-top: 3rem;
    width: 90%;
  }
  .not-found-section .img-err-container .img-err {
    width: 100%;
  }
}
@media screen and (min-width: 768px) and (max-width: 1199px) {
  .not-found-section .info-err-container {
    margin-left: 10%;
  }
  .not-found-section .img-err-container {
    margin-right: 10%;
  }
}/*# sourceMappingURL=BodyNotFoundPage.css.map */