/*Colors*/
/*Colors Opacity*/
/*Global*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.goals-section {
  height: 100%;
}
.goals-section .deco-title {
  width: 1.5rem;
  height: 1rem;
  border: 1px solid grey;
  background-color: grey;
  border-radius: 31px 0px 0px 32px;
}
.goals-section .deco-oposite {
  border: 1px solid #ffffff;
  background-color: #ffffff;
}
.goals-section .goals-title-container {
  padding-top: 3rem;
  width: 50%;
  display: flex;
  color: rgb(78, 78, 78);
}
.goals-section .half-section-container {
  height: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.goals-section .half-section-container .goals-info-subcontainer {
  display: flex;
  margin-top: 2%;
  color: #ffffff;
  -moz-column-gap: 8rem;
       column-gap: 8rem;
  align-items: center;
}
.goals-section .half-section-container .vision-elm-container {
  display: flex;
  align-items: center;
  max-width: 25rem;
  color: #ffffff;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
  margin-top: 0.5rem;
}
.goals-section .half-section-container .title-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 2rem;
  -moz-column-gap: 0.5rem;
       column-gap: 0.5rem;
}
.goals-section .half-section-container .vision-title-pos {
  position: relative;
  top: -1rem;
}
.goals-section .half-section-container .separation-line {
  border-left: 1px solid #ffffff;
  height: 50%;
}
.goals-section .info-estrategy-container {
  margin-bottom: 1.5rem;
}
.goals-section .icons-container {
  display: flex;
  align-items: flex-end;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
}
.goals-section .icons-container .estrategy-icon {
  width: 1.5rem;
  height: 1.5rem;
}
.goals-section .first {
  background-image: url("https://pagina-otacc.s3.amazonaws.com/pages/otacc-page/aboutus/assets/section-second-bg-2.webp");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.goals-section .second {
  background-image: url("https://pagina-otacc.s3.amazonaws.com/pages/otacc-page/aboutus/assets/section-second-bg-1.webp");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.goals-section .info-first-half-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 50%;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
  margin-bottom: 3rem;
}
.goals-section .info-first-half-container .info-fh-elm-container {
  display: flex;
  width: 20rem;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
}
.goals-section .info-first-half-container .title-icon {
  width: 2rem;
}
.goals-section .info-first-half-container .num-container {
  font-size: 2rem;
  color: #ffffff;
}
.goals-section .info-first-half-container .text-info-elm-container {
  display: flex;
  flex-direction: column;
  color: rgb(78, 78, 78);
}
.goals-section .info-first-half-container .title-fh-elm-container {
  display: flex;
  align-items: center;
  color: rgb(78, 78, 78);
}
.goals-section .waw-sh-info-container {
  display: flex;
  flex-direction: column;
  color: #ffffff;
  align-items: center;
  width: 50%;
  margin-top: 3rem;
  row-gap: 3.5rem;
}
.goals-section .waw-sh-info-container .subtitle-waw-sh {
  align-self: flex-start;
  color: rgb(78, 78, 78);
}
.goals-section .info-second-half-container {
  height: 50%;
  display: flex;
  justify-content: center;
  -moz-column-gap: 1.5rem;
       column-gap: 1.5rem;
}
.goals-section .info-second-half-container .info-sh-subcontainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: 2rem;
  color: rgb(78, 78, 78);
}
.goals-section .info-second-half-container .info-sh-icon {
  width: 5rem;
  height: 5rem;
}

@media screen and (min-width: 320px) and (max-width: 479px) {
  .goals-section {
    height: unset;
  }
  .goals-section .first {
    padding-bottom: 3rem;
    background-position-x: center;
    background-size: cover;
    padding-top: 3rem;
  }
  .goals-section .second {
    padding-bottom: 3rem;
    background-position-x: center;
    background-size: cover;
  }
  .goals-section .goals-title-container {
    width: unset;
  }
  .goals-section .info-first-half-container {
    flex-direction: column;
    width: 90%;
    row-gap: 1.5rem;
  }
  .goals-section .info-first-half-container .info-fh-elm-container {
    width: unset;
  }
  .goals-section .info-first-half-container .title-icon {
    display: none;
  }
  .goals-section .num-icon {
    width: 3rem;
  }
  .goals-section .title-container {
    justify-content: center !important;
  }
  .goals-section .vision-title-pos {
    position: unset !important;
  }
  .goals-section .goals-info-subcontainer {
    flex-direction: column;
    width: 90%;
    row-gap: 2rem;
  }
  .goals-section .goals-info-subcontainer .title {
    text-align: center;
  }
  .goals-section .goals-info-subcontainer .separation-line {
    width: 50%;
  }
}
@media screen and (min-width: 480px) and (max-width: 767px) {
  .goals-section {
    height: unset;
  }
  .goals-section .first {
    padding-bottom: 3rem;
    background-position-x: center;
    background-size: cover;
    padding-top: 3rem;
  }
  .goals-section .second {
    padding-bottom: 3rem;
    background-position-x: center;
    background-size: 151rem;
  }
  .goals-section .goals-title-container {
    width: unset;
  }
  .goals-section .info-first-half-container {
    flex-direction: column;
    width: 80%;
    row-gap: 1.5rem;
  }
  .goals-section .info-first-half-container .info-fh-elm-container {
    width: unset;
  }
  .goals-section .info-first-half-container .title-icon {
    display: none;
  }
  .goals-section .num-icon {
    width: 3rem;
  }
  .goals-section .title-container {
    justify-content: center !important;
  }
  .goals-section .vision-title-pos {
    position: unset !important;
    text-align: center;
  }
  .goals-section .goals-info-subcontainer {
    flex-direction: column;
    width: 80%;
    row-gap: 2rem;
  }
  .goals-section .goals-info-subcontainer .title {
    text-align: center;
  }
  .goals-section .goals-info-subcontainer .separation-line {
    width: 50%;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .goals-section {
    height: unset;
  }
  .goals-section .first {
    padding-bottom: 3rem;
    background-position-x: center;
    background-position-y: bottom;
    background-size: 125rem;
    padding-top: 3rem;
  }
  .goals-section .second {
    padding-bottom: 3rem;
    background-position-x: center;
    background-size: 125rem;
  }
  .goals-section .info-first-half-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 90%;
  }
  .goals-section .info-first-half-container .info-fh-elm-container {
    width: unset;
  }
  .goals-section .info-first-half-container .title-icon {
    display: none;
  }
  .goals-section .num-icon {
    width: 3rem;
  }
  .goals-section .goals-info-subcontainer {
    width: 90%;
    -moz-column-gap: 2rem !important;
         column-gap: 2rem !important;
  }
  .goals-section .goals-info-subcontainer .title {
    text-align: center;
  }
  .goals-section .goals-info-subcontainer .separation-line {
    height: 10rem;
  }
  .goals-section .goals-info-subcontainer .info-vision-container {
    width: 100%;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1199px) {
  .goals-section {
    height: unset;
  }
  .goals-section .first {
    padding-bottom: 3rem;
    background-position-x: center;
    background-position-y: bottom;
    background-size: 100rem;
    padding-top: 3rem;
  }
  .goals-section .second {
    padding-bottom: 3rem;
    background-position-x: center;
    background-size: 100rem;
  }
  .goals-section .info-first-half-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 90%;
  }
  .goals-section .info-first-half-container .info-fh-elm-container {
    width: unset;
  }
  .goals-section .info-first-half-container .title-icon {
    display: none;
  }
  .goals-section .num-icon {
    width: 3rem;
  }
  .goals-section .goals-info-subcontainer {
    width: 90%;
    -moz-column-gap: 2rem !important;
         column-gap: 2rem !important;
  }
  .goals-section .goals-info-subcontainer .title {
    text-align: center;
  }
  .goals-section .goals-info-subcontainer .separation-line {
    height: 10rem;
  }
  .goals-section .goals-info-subcontainer .info-vision-container {
    width: 100%;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1919px) {
  .goals-section {
    overflow: hidden;
  }
  .goals-section .first {
    padding-bottom: 3rem;
    background-position-x: center;
    background-position-y: bottom;
    background-size: cover;
  }
  .goals-section .second {
    padding-bottom: 3rem;
    background-position-x: center;
    background-size: cover;
  }
  .goals-section .goals-title-container {
    width: 100%;
    margin-left: 10%;
  }
  .goals-section .info-first-half-container {
    width: 90%;
  }
  .goals-section .info-first-half-container .info-fh-elm-container {
    width: 100%;
  }
  .goals-section .goals-info-subcontainer {
    width: 90%;
    -moz-column-gap: 2rem !important;
         column-gap: 2rem !important;
    height: 100%;
  }
  .goals-section .goals-info-subcontainer .title {
    text-align: center;
  }
  .goals-section .goals-info-subcontainer .separation-line {
    height: 10rem;
  }
  .goals-section .goals-info-subcontainer .info-vision-container {
    width: 100%;
  }
}
@media screen and (min-width: 1920px) {
  .separation-line {
    height: 10rem !important;
  }
}/*# sourceMappingURL=GoalsAboutUsSection.css.map */