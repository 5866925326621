/*Colors*/
/*Colors Opacity*/
/*Global*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.bg-pg {
  background-image: url("https://pagina-otacc.s3.amazonaws.com/pages/otacc-page/assets/bgs/map-bg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: center;
  height: 100vh;
  width: 100%;
  z-index: -1;
  position: absolute;
  bottom: -100vh;
}/*# sourceMappingURL=BodyOilAndGas.css.map */