@import "../../sass/variables";

.info-ene-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 5rem 0;
  .title-container {
    margin-left: 10%;
    display: flex;
    align-items: center;
    column-gap: 1rem;
    .deco-title {
      width: 1.5rem;
      height: 1rem;
      border: 1px solid $btnColorSecondary;
      background-color: $btnColorSecondary;
      border-radius: 31px 0px 0px 32px;
    }
    .ene-title {
      color: $btnColorPrimary;
      font-weight: normal;
    }
  }
  .info-ene-supercontainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .info-ene-container {
    display: flex;
    justify-content: center;
    position: relative;
    align-items: center;
    flex-direction: column;
    .ene-subtitle {
      color: $btnColorSecondary;
      font-size: 1.5rem;
    }
    li {
      color: $shadowInfoCounter;
      font-size: 1.1rem;
    }
  }
  .info-pd-counter-container {
    width: 90%;
    background-color: $bgInfoCounter;
    padding: 1rem;
    border-radius: 0.5rem;
    .info-counter-subcontainer {
      padding: 0.5rem;
      background-color: $bgInfoCounterSecondary;
      border-radius: 0.5rem;
      box-shadow: 6px 6px $shadowInfoCounter;
    }
    .info-counter-list {
      display: flex;
      justify-content: space-between;
      color: darken($shadowInfoCounter, $amount: 50);
    }
  }
  .info-ene-title {
    margin-bottom: 1rem;
  }
  .info-ene-txt-container {
    margin: 2rem 0 0 10%;
  }
}

//Responsive Mobile Small
@media screen and (min-width: $mobileSm) and (max-width: calc($tabletSm - 1px)) {
  .info-ene-section {
    .info-ene-supercontainer {
      grid-template-columns: unset;
      grid-template-rows: 1fr 1fr;
      width: 90%;
    }
    .info-pd-counter-container {
      width: 70%;
    }
    .info-counter-list {
      flex-direction: column;
    }
  }
}

//Responsive Tablet Small
@media screen and (min-width: $tabletSm) and (max-width: calc($tabletStandard - 1px)) {
  .info-ene-supercontainer {
    width: 90%;
  }
}
