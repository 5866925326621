/*Colors*/
/*Colors Opacity*/
/*Global*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.gallery-container {
  display: flex;
  align-items: center;
  z-index: 2;
}
.gallery-container .img-container {
  position: relative;
}
.gallery-container .img-container .gallery-img {
  border-radius: 0.5rem;
  margin-right: 1rem;
  height: 18rem;
  width: 30rem;
}
.gallery-container .thumbs-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 18.5rem;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
  row-gap: 1rem;
  padding-top: 0.3rem;
}
.gallery-container .thumbs-container .thumb {
  cursor: pointer;
}
.gallery-container .thumbs-container .last {
  position: relative;
}
.gallery-container .thumbs-container .more-btn-container {
  position: absolute;
  background-color: rgba(59, 62, 67, 0.568627451);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 0.5rem;
}
.gallery-container .thumbs-container .img-total {
  color: #ffffff;
  font-size: 1.3rem;
  margin-bottom: 0.2rem;
}
.gallery-container .thumbs-container .more-btn {
  width: 70%;
  height: 2rem;
  cursor: pointer;
  background-color: #3b3e43;
  border: #3b3e43;
  border-radius: 0.5rem;
  color: #ffffff;
}
.gallery-container .thumbs-container .more-btn:hover {
  background-color: #5e5e5e;
}
.gallery-container .thumbs-container .more-btn:active {
  background-color: #5e5e5e;
  background-size: 100%;
  transition: background 0s;
}
.gallery-container .thumbs-container .thumb-img {
  width: 15rem;
  height: 8.5rem;
  border-radius: 0.5rem;
}

/*Responsive Tablet Small*/
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .thumbs-container {
    grid-template-columns: 1fr !important;
  }
}/*# sourceMappingURL=GridGallery.css.map */