@import "../../sass/variables";

.clients-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3rem 0;
  .clients-title-container {
    margin: 3rem 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .clients-title {
      margin-bottom: 1rem;
      font-size: 1.8rem;
    }
  }
  .clients-slide-container {
    width: 90%;
  }
  .carousel-no-mobile-clients {
    display: flex;
    justify-content: center;
    .clients-minor-eight-container {
      display: flex;
      justify-content: space-between;
      width: 60%;
    }
  }
}

/*Responsive Mobile Small*/

@media screen and (min-width: $mobileSm) and (max-width: calc($mobileStandard - 1px)) {
  .clients-title-container {
    font-size: 1rem !important;
  }
  .carousel-no-mobile-clients {
    display: none !important;
  }
  .clients-minor-eight-container {
    display: none !important;
  }
}

/*Responsive Hide Carousel Mobile*/

@media screen and (min-width: $mobileStandard) {
  .carousel-mobile {
    display: none;
  }
}

//Responsive Mobile Standard
@media screen and (min-width: $mobileStandard) and (max-width: calc($tabletStandard - 1px)) {
  .clients-minor-eight-container {
    width: 50% !important;
  }
}
