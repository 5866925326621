/*Colors*/
/*Colors Opacity*/
/*Global*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.info-oag-section {
  width: 100%;
  display: flex;
  padding: 5rem 0;
  flex-direction: column;
}
.info-oag-section .title-container {
  margin-left: 10%;
  display: flex;
  align-items: center;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
}
.info-oag-section .title-container .deco-title {
  width: 1.5rem;
  height: 1rem;
  border: 1px solid #00764F;
  background-color: #00764F;
  border-radius: 31px 0px 0px 32px;
}
.info-oag-section .title-container .oag-title {
  color: #3b3e43;
  font-weight: normal;
}
.info-oag-section .info-oag-supercontainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.info-oag-section .info-oag-container {
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.info-oag-section .info-oag-container .oag-subtitle {
  color: #00764F;
  font-size: 1.5rem;
}
.info-oag-section .info-oag-container li {
  color: #ada4a9;
  font-size: 1.1rem;
}
.info-oag-section .border-line {
  border-left: 1px solid #000000;
}
.info-oag-section .info-pd-counter-container {
  width: 90%;
  background-color: #d6ccd4;
  padding: 1rem;
  border-radius: 0.5rem;
}
.info-oag-section .info-pd-counter-container .info-counter-subcontainer {
  padding: 0.5rem;
  background-color: #e5dfe3;
  border-radius: 0.5rem;
  box-shadow: 6px 6px #ada4a9;
}
.info-oag-section .info-pd-counter-container .info-counter-list {
  display: flex;
  flex-wrap: wrap;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
  justify-content: space-between;
  color: #2b2729;
}
.info-oag-section .info-oag-title {
  margin-bottom: 1rem;
}
.info-oag-section .info-oag-txt-container {
  margin: 2rem 0 0 10%;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .info-oag-section .info-oag-supercontainer {
    grid-template-columns: unset;
    grid-template-rows: 1fr 1fr;
    width: 90%;
  }
  .info-oag-section .info-pd-counter-container {
    width: 70%;
  }
  .info-oag-section .info-counter-list {
    flex-direction: column;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .info-oag-supercontainer {
    width: 90%;
  }
}/*# sourceMappingURL=InfoOilAndGasSection.css.map */