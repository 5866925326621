@import "../../sass/variables";

.grid-au-gallery-section {
  padding: 2.5rem 0;
  background-color: $basicColorPrimary;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 1.5rem;
  .grid-title-container {
    padding-top: 3rem;
    width: 50%;
    display: flex;
    color: rgb(78, 78, 78);
    align-items: center;
    column-gap: 0.5rem;
    .deco-title {
      width: 1.5rem;
      height: 1rem;
      border: 1px solid grey;
      background-color: grey;
      border-radius: 31px 0px 0px 32px;
    }
  }
  .fs-gallery-container {
    position: relative;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    display: flex;
    justify-content: flex-end;
    .reset-value-gallery {
      position: fixed;
      width: 6rem;
      height: 5rem;
      z-index: 9999;
      top: 0;
      right: 0;
      cursor: pointer;
    }
  }
  .carousel-mobile-hor {
    display: none;
    z-index: 2;
  }
}

/*Responsive Mobile Small*/

@media screen and (min-width: $mobileSm) and (max-width: calc($mobileStandard - 1px)) {
  .grid-title-container {
    width: unset !important;
  }
  .gallery-container {
    display: none !important;
  }
  .reset-value-gallery {
    display: none !important;
  }
  .carousel-mobile-hor {
    display: unset !important;
    height: 18rem;
  }
}

/*Responsive Mobile Standard*/

@media screen and (min-width: $mobileStandard) and (max-width: calc($tabletSm - 1px)) {
  .grid-title-container {
    width: unset !important;
  }
  .gallery-container {
    display: none !important;
  }
  .reset-value-gallery {
    display: none !important;
  }
  .carousel-mobile-hor {
    display: unset !important;
    height: 20rem;
  }
}

/*Responsive Tablet Small*/

@media screen and (min-width: $tabletSm) and (max-width: calc($tabletStandard - 1px)) {
  .carousel-mobile-hor {
    display: unset;
  }
}

//Responsive Tablet Small >
@media screen and (min-width: $tabletSm) {
  .gallery-hor-section {
    margin: 5.5rem 0 5.5rem 0;
  }
}
