@import "../../sass/variables";

.footer-container {
  border-top: 1px solid $basicColorSecondary;
  padding: 4rem 5%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 2rem;
  h3 {
    margin-bottom: 1rem;
  }
  .social-media-footer {
    margin-top: 1rem;
    .social-media-container {
      display: flex;
      column-gap: 0.5rem;
    }
  }
  .social-media {
    width: 2rem;
    height: 2rem;
  }
  .footer-info-container {
    display: flex;
    flex-direction: column;
  }
  .doc-anchor {
    color: $basicColorSecondary;
    &:link {
      color: $basicColorSecondary;
      text-decoration: none;
    }
    &:visited {
      color: $basicColorSecondary;
    }
    &:active {
      color: $basicColorSecondary;
    }
  }
  .foundation-btn-container {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    column-gap: 1rem;
    justify-content: space-between;
    .anchor-foundation {
      border: 2px solid $basicColorSecondary;
      border-radius: 0.5rem;
      padding: 0.2rem 1rem 0.2rem 1rem;
      display: flex;
      align-items: center;
      column-gap: 1rem;
      height: 2rem;
      color: $basicColorSecondary;
      &:link {
        color: $basicColorSecondary;
        text-decoration: none;
      }
      &:visited {
        color: $basicColorSecondary;
      }
      &:active {
        color: $basicColorSecondary;
      }
      &:hover {
        background-color: $basicColorSecondary;
        color: $basicColorPrimary;
        .separation-line-btn {
          border-left: 1px solid $basicColorPrimary;
        }
      }
    }
    .separation-line-btn {
      height: 2.2rem;
      border-left: 1px solid $basicColorSecondary;
    }
    .icon-btn-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .btn-icon {
      width: 2rem;
    }
  }
}

/*Responsive Mobile Small*/

@media screen and (min-width: $mobileSm) and (max-width: calc($tabletSm - 1px)) {
  .footer-container {
    grid-template-columns: 1fr;
    text-align: center;
    row-gap: 1rem;
    .footer-info-container {
      font-size: 0.8rem;
      display: flex;
      align-items: center;
      flex-direction: column;
    }
    .social-media-container {
      justify-content: center;
    }
  }
}

/*Responsive Mobile Standard*/

@media screen and (min-width: $mobileStandard) and (max-width: calc($tabletSm - 1px)) {
  .footer-container {
    grid-template-columns: 1fr;
    text-align: center;
    row-gap: 1rem;
    .footer-info-container {
      font-size: 0.8rem;
      display: flex;
      align-items: center;
      flex-direction: column;
    }
    .social-media-container {
      justify-content: center;
    }
  }
}

/*Responsive Tablet Small*/

@media screen and (min-width: $tabletSm) and (max-width: calc($tabletStandard - 1px)) {
  .footer-container {
    grid-template-columns: 1fr;
    text-align: center;
    row-gap: 1rem;
    padding: 4rem 10%;
    .footer-info-container {
      font-size: unset;
      display: flex;
      align-items: center;
      flex-direction: column;
    }
    .social-media-container {
      justify-content: center;
    }
  }
}
