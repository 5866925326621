/*Colors*/

$basicColorPrimary: #ffffff;
$basicColorSecondary: #000000;
$navbarSelectedColor: #e1e1e1;
$btnColorPrimary: #3b3e43;
$bgInfoCounter: #d6ccd4;
$bgInfoCounterSecondary: #e5dfe3;
$shadowInfoCounter: #ada4a9;
$propDevsCounterColor: #a18480;
$btnColorPrimaryHover: #5e5e5e;
$bgColorGridGalleryPD: #2c2c2c;
$btnColorSecondary: #00764F;

/*Colors Opacity*/
$navbarBgColorOpa: #ffffffbf;
$navbarSelectedColorOpa: #e1e1e19d;
$cardHoverOpa: #3b3e4391;
$bgGalleryEne: #000000c5;

/*Global*/
* {
    margin: 0;
}

/*Responsive - breakpoints*/
$mobileSm: 320px;
$mobileStandard: 480px;
$tabletSm: 768px;
$tabletStandard: 1024px;
$screenSm: 1200px;
$screenHd: 1920px;
$screen4k: 2160px;