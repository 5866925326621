/*Colors*/
/*Colors Opacity*/
/*Global*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.job-offer-grid-section {
  width: 100%;
  display: flex;
  justify-content: center;
}
.job-offer-grid-section .job-offer-grid-subcontainer {
  width: 1024px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 4rem;
  row-gap: 2rem;
}
.job-offer-grid-section .job-offer-grid-subcontainer .jog-title {
  color: #131313;
  font-size: 2rem;
  font-weight: 500;
}
.job-offer-grid-section .job-offer-grid-subcontainer .jog-content-container {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  width: 100%;
}
.job-offer-grid-section .job-offer-grid-subcontainer .jog-c-filter-container {
  display: flex;
  justify-content: center;
  -moz-column-gap: 3rem;
       column-gap: 3rem;
}
.job-offer-grid-section .job-offer-grid-subcontainer .jog-c-f-search-container {
  border: 1px solid #2c2c2c;
  border-radius: 1rem;
  overflow: hidden;
  background-color: #ffffff;
}
.job-offer-grid-section .job-offer-grid-subcontainer .jog-c-f-s-input {
  padding: 0.5rem 0 0.25rem 1rem;
  border-color: transparent;
  outline: none;
  width: 15rem;
  color: #2c2c2c;
}
.job-offer-grid-section .job-offer-grid-subcontainer .jog-c-f-s-search-btn {
  height: 100%;
  margin-right: 0.5rem;
  background-color: #2c2c2c;
  border-radius: 100%;
  border-color: #2c2c2c;
  outline: none;
  cursor: pointer;
}
.job-offer-grid-section .job-offer-grid-subcontainer .jog-c-f-s-search-btn:hover {
  background-color: #ffffff;
}
.job-offer-grid-section .job-offer-grid-subcontainer .jog-c-f-s-sb-icon {
  width: 1rem;
  height: auto;
}
.job-offer-grid-section .job-offer-grid-subcontainer .jog-c-f-select {
  border: 1px solid #2c2c2c;
  height: 100%;
  border-radius: 1rem;
  padding: 0 1rem;
  color: #2c2c2c;
  outline: none;
}
.job-offer-grid-section .job-offer-grid-subcontainer .jog-c-grid-container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  -moz-column-gap: 2rem;
       column-gap: 2rem;
  row-gap: 2rem;
}
.job-offer-grid-section .job-offer-grid-subcontainer .jog-c-g-card-container {
  border: 1px solid #2c2c2c;
  padding: 1rem 2rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
.job-offer-grid-section .job-offer-grid-subcontainer .jog-c-g-card-title {
  font-weight: 500;
  color: #2c2c2c;
}
.job-offer-grid-section .job-offer-grid-subcontainer .jog-c-g-card-type-container {
  display: flex;
  align-items: flex-start;
  -moz-column-gap: 0.5rem;
       column-gap: 0.5rem;
  color: #2c2c2c;
}
.job-offer-grid-section .job-offer-grid-subcontainer .jog-c-g-ct-icon {
  width: auto;
  height: 1.5rem;
}
.job-offer-grid-section .job-offer-grid-subcontainer .jog-c-g-btn-elm-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.job-offer-grid-section .job-offer-grid-subcontainer .jog-c-g-be-btn {
  background-color: #2c2c2c;
  border: 1px solid #2c2c2c;
  color: #ffffff;
  border-radius: 1rem;
  font-size: 0.8rem;
  padding: 0.25rem 0.5rem;
  cursor: pointer;
  text-decoration: none;
}
.job-offer-grid-section .job-offer-grid-subcontainer .jog-c-g-be-btn:hover {
  background-color: #ffffff;
  color: #2c2c2c;
}

@media screen and (min-width: 320px) and (max-width: 479px) {
  .job-offer-grid-subcontainer {
    width: 90% !important;
  }
  .job-offer-grid-subcontainer .jog-title {
    font-size: 1.5rem !important;
    text-align: center;
  }
  .job-offer-grid-subcontainer .jog-c-filter-container {
    flex-direction: column;
    row-gap: 1rem;
  }
  .job-offer-grid-subcontainer .jog-c-filter-container .jog-c-f-select {
    width: 100%;
    padding: 0.5rem 1rem !important;
  }
  .job-offer-grid-subcontainer .jog-c-filter-container .jog-c-f-search-container {
    position: relative;
  }
  .job-offer-grid-subcontainer .jog-c-filter-container .jog-c-f-s-search-btn {
    position: absolute;
    right: 5%;
  }
  .job-offer-grid-subcontainer .jog-c-grid-container {
    grid-template-columns: 1fr !important;
  }
}
@media screen and (min-width: 480px) and (max-width: 767px) {
  .job-offer-grid-subcontainer {
    width: 80% !important;
  }
  .job-offer-grid-subcontainer .jog-title {
    font-size: 1.5rem !important;
    text-align: center;
  }
  .job-offer-grid-subcontainer .jog-c-filter-container {
    flex-direction: column;
    row-gap: 1rem;
  }
  .job-offer-grid-subcontainer .jog-c-filter-container .jog-c-f-select {
    width: 100%;
    padding: 0.5rem 1rem !important;
  }
  .job-offer-grid-subcontainer .jog-c-filter-container .jog-c-f-search-container {
    position: relative;
  }
  .job-offer-grid-subcontainer .jog-c-filter-container .jog-c-f-s-search-btn {
    position: absolute;
    right: 5%;
  }
  .job-offer-grid-subcontainer .jog-c-grid-container {
    grid-template-columns: 1fr !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .job-offer-grid-subcontainer {
    width: 90% !important;
  }
  .job-offer-grid-subcontainer .jog-title {
    text-align: center;
  }
  .job-offer-grid-subcontainer .jog-c-f-select {
    width: 100%;
    padding: 0.5rem 1rem !important;
  }
  .job-offer-grid-subcontainer .jog-c-f-search-container {
    position: relative;
  }
  .job-offer-grid-subcontainer .jog-c-f-s-search-btn {
    position: absolute;
    right: 5%;
  }
  .job-offer-grid-subcontainer .jog-c-grid-container {
    grid-template-columns: 1fr 1fr !important;
  }
}/*# sourceMappingURL=JobOfferGridSection.css.map */