/*Colors*/
/*Colors Opacity*/
/*Global*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.body-container {
  height: 100vh;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
}/*# sourceMappingURL=Body.css.map */