@import "../../sass/variables";

.pd-counter-section {
  width: 100%;
  max-height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  overflow: hidden;
  .pd-counter-video {
    transform: scale(1.5);
    height: 100vh;
  }
  .pd-counter-img {
    transform: scale(1.3);
  }
  .pd-counters-container {
    display: flex;
    position: absolute;
    bottom: 2rem;
    column-gap: 2rem;
    flex-wrap: wrap;
    justify-content: center;
  }
}

/*Responsive Mobile Small*/

@media screen and (min-width: $mobileSm) and (max-width: calc($mobileStandard - 1px)) {
  .pd-counter-section {
    overflow: hidden;
    .pd-counter-img {
      width: 57rem;
    }
    .pd-counters-container {
      margin: 0 0.5rem;
      column-gap: 1rem;
    }
    .info-pd-counter-container {
        width: 70%;
    }
    .info-counter-list {
        flex-direction: column;
    }
  }
}

/*Responsive Mobile Standard*/

@media screen and (min-width: $mobileStandard) and (max-width: calc($tabletSm - 1px)) {
  .pd-counter-section {
    overflow: hidden;
    .pd-counter-img {
      width: 99rem;
    }
    .pd-counters-container {
      margin: 0 0.5rem;
      column-gap: 1rem;
    }
    .info-pd-counter-container {
        width: 70%;
    }
  }
}

/*Responsive Tablet Small*/

@media screen and (min-width: $tabletSm) and (max-width: calc($tabletStandard - 1px)) {
  .pd-counter-section {
    overflow: hidden;
    .pd-counter-img {
      width: 120rem;
    }
  }
}

//Responsive Tablet Standard - Screen Small
@media screen and (min-width: $tabletStandard) and (max-width: calc($screenHd - 1px)) {
  .pd-counter-img {
    transform: unset !important;
    width: 100% !important;
  }
}