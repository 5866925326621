@import "../../sass/variables";

.docs-section {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 3rem;
  padding: 3rem 0;
  background-image: url('https://pagina-otacc.s3.amazonaws.com/pages/otacc-page/assets/bgs/map-bg.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: center;
  .separation-line-mb {
    display: none;
    width: 50%;
  }
  .separation-line {
    height: 10rem;
    border-left: 1px solid black;
  }
}

//Responsive Mobile Small
@media screen and (min-width: $mobileSm) and (max-width: calc($tabletSm - 1px)) {
  .docs-section {
    flex-direction: column;
    row-gap: 3rem;
    padding: 3rem 1rem;
    .separation-line-mb {
      display: unset;
    }
    .separation-line {
      width: 2rem;
      height: unset;
    }
  }
}