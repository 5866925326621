@import '../../sass/_variables.scss';

.nb-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
    position: fixed;
    z-index: 2;
    .nb-logo-container {
        margin-left: 3%;
        .nb-logo-img {
            width: 9rem;
            height: 100%;
        }
    }
    .nb-menus-container-nm {
        display: flex;
        align-items: center;
        border: 1px solid $navbarBgColorOpa;
        justify-content: space-around;
        margin-right: 5%;
        border-radius: 0.5rem;
        background-color: $navbarBgColorOpa;
        .nb-option {
            margin: 0 2rem;
            color: $basicColorSecondary;
            &:link {
                color: $basicColorSecondary;
                text-decoration: none;
            }
            &:visited {
                color: $basicColorSecondary;
            }
            &:active {
                color: $basicColorSecondary;
            }
        }
        .current {
            border: 1px solid $navbarSelectedColor;
            background-color: $navbarSelectedColor;
            padding: 0.3rem 1rem;
            border-radius: 0.5rem;
            margin: 0;
        }
    }
    .nb-menus-mobile-container {
        display: none;
        margin-right: 5%;
        .ham-icon {
            width: 1rem;
            height: 1rem;
            cursor: pointer;
        }
        .mb-menus-container {
            width: 100%;
            background-color: $navbarBgColorOpa;
            z-index: 2;
            position: fixed;
            top: 5rem;
            left: 0;
            padding: 1rem 2rem;
            transition: all 2s;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
        }
        .nb-option {
            margin: 0 3rem 1rem 0;
            color: $basicColorSecondary;
            &:link {
                color: $basicColorSecondary;
                text-decoration: none;
            }
            &:visited {
                color: $basicColorSecondary;
            }
            &:active {
                color: $basicColorSecondary;
            }
        }
        .closed-menu {
            @keyframes close {
                0% {left: 0rem;}
                100% {left: 65rem;}
            }
            animation-name: close;
            animation-duration: 1.5s;
            left: 65rem;
        }
    }
}

/*Responsive Mobile Small - Tablet Standard*/

@media screen and (min-width: $mobileSm) and (max-width: calc($tabletStandard - 1px)) {
    .nb-logo-img {
        width: 6rem !important;
    }
    .nb-menus-container-nm {
        display: none !important;
    }
    .nb-menus-mobile-container {
        display: unset !important;
    }
  }