@import '../../sass/variables';

.otacc-gallery-container {
    position: fixed;
    width: 100%;
    height: 100vh;
    background-color: $bgGalleryEne;
    z-index: 99;
    display: none;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .close-btn-container {
        display: flex;
        justify-content: flex-end;
        align-self: flex-end;
        margin-right: 2%;
        .close-icon {
            width: 1rem;
            color: $basicColorPrimary;
            font-size: 1.5rem;
            cursor: pointer;
        }
    }
    .imgs-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 1.5rem;
        width: 100%;
        margin:  0 5%;
        .icon-gallery {
            color: $basicColorPrimary;
            font-size: 2rem;
            cursor: pointer;
            margin: 0 10%;
        }
        .img-container {
            height: 40rem;
            overflow: hidden;
        }
        .img-gallery {
            height: 100%;
            width: 100%;
        }
    }
}

.open {
    display: flex;
}

//Responsive Mobile Small - Tablet Standard
@media screen and (min-width: $mobileSm) and (max-width: calc($tabletStandard - 1px)) {
    .icon-gallery {
        display: none;
    }
    .img-container {
        max-height: 24rem !important;
        height: unset !important;
    }
    .imgs-container {
        flex-direction: column;
    }
}

//Responsive Tablet Standard
@media screen and (min-width: $tabletStandard) and (max-width: calc($screenSm - 1px)) {
    .img-container {
        max-height: 30rem !important;
        height: unset !important;
    }
}

//Responsive Small Screen
@media screen and (min-width: $screenSm) and (max-width: calc($screenHd - 1px)) {
    .icon-gallery {
        margin: 0 5% !important;
    }
}