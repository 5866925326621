/*Colors*/
/*Colors Opacity*/
/*Global*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.projects-section {
  width: 100%;
}
.projects-section .title-section-container {
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 4rem 0;
}
.projects-section .title-section-container .section-title {
  font-size: 1.8rem;
  margin-bottom: 1rem;
}
.projects-section .show-title {
  display: flex;
}
.projects-section .prjs-gallery {
  display: none;
}
.projects-section .open-gallery {
  display: unset;
}
.projects-section .close-gallery {
  width: 5rem;
  height: 5rem;
  z-index: 9999;
  position: fixed;
  top: 0;
  right: 0;
  cursor: pointer;
}
.projects-section .grid-prjs-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.projects-section .carousel-no-mobile {
  display: none;
}
.projects-section .show-propdevs {
  display: grid;
}
.projects-section .project-container {
  overflow: hidden;
  width: 100%;
  max-height: 50rem;
  position: relative;
}
.projects-section .project-container .hover-view-container {
  position: absolute;
  width: 100%;
  height: 0%;
  background-color: rgba(59, 62, 67, 0.568627451);
  z-index: 2;
  opacity: 0;
  transition: all 0.5s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 5%;
  row-gap: 2rem;
}
.projects-section .project-container .hover-view-container .hv-title {
  font-size: 1.8rem;
  color: #ffffff;
  width: 90%;
}
.projects-section .project-container .hover-view-container .subtitle-info {
  color: #ffffff;
  font-size: 1rem;
}
.projects-section .project-container .hover-view-container .sub {
  font-size: 1.2rem;
}
.projects-section .project-container .hover-view-container .show-more-btn-container {
  width: 90%;
  position: relative;
  margin-top: 2.5rem;
}
.projects-section .project-container .hover-view-container .show-gallery-btn {
  position: absolute;
  bottom: -1.2rem;
  right: 2%;
  width: 2.5rem;
  height: 2.5rem;
  font-size: 2rem;
  font-weight: bold;
  border: 1px solid #00764F;
  border-radius: 2rem;
  background-color: #ffffff;
  color: #00764F;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.projects-section .project-container .hover-view-container .show-gallery-btn:hover {
  border-color: #00764F;
  background-color: #00764F;
  color: #ffffff;
}
.projects-section .project-container:hover .hover-view-container {
  height: 100%;
  opacity: 1;
}
.projects-section .project-container .project-thumbnail {
  width: 100%;
  height: 100%;
}
.projects-section .project-container .prop-section {
  filter: grayscale(80%);
  transition: all ease 1s;
}
.projects-section .project-container .prop-section:hover {
  filter: grayscale(0%);
}

/*Responsive Mobile Small*/
@media screen and (min-width: 320px) and (max-width: 479px) {
  .energy-section .project-thumbnail {
    height: 100%;
  }
  .energy-section .show-gallery-btn {
    width: unset !important;
  }
  .hover-view-container {
    row-gap: 0 !important;
  }
  .hover-view-container .hv-title {
    font-size: 0.8rem !important;
  }
  .hover-view-container .subtitle-info {
    font-size: 0.5rem !important;
  }
  .hover-view-container .sub {
    width: 60% !important;
    font-size: 0.8rem !important;
  }
}
@media screen and (min-width: 480px) and (max-width: 767px) {
  .energy-section .project-thumbnail {
    height: 100%;
  }
  .energy-section .hv-title {
    font-size: 1rem !important;
  }
  .energy-section .show-gallery-btn {
    width: unset !important;
  }
  .hover-view-container {
    row-gap: 0 !important;
  }
  .hover-view-container .hv-title {
    font-size: 0.8rem !important;
  }
  .hover-view-container .subtitle-info {
    font-size: 0.5rem !important;
  }
  .hover-view-container .sub {
    width: 60% !important;
    font-size: 0.8rem !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .hv-title {
    font-size: 1rem !important;
  }
  .hover-view-container {
    row-gap: 0 !important;
  }
  .hover-view-container .hv-title {
    font-size: 1rem !important;
  }
  .hover-view-container .subtitle-info {
    font-size: 0.7rem !important;
  }
  .hover-view-container .sub {
    width: 60% !important;
    font-size: 1rem !important;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1199px) {
  .hv-title {
    font-size: 1.2rem !important;
  }
  .hover-view-container {
    row-gap: 0 !important;
  }
  .hover-view-container .hv-title {
    font-size: 1.1rem !important;
  }
  .hover-view-container .subtitle-info {
    font-size: 0.8rem !important;
  }
  .hover-view-container .sub {
    width: 60% !important;
    font-size: 1.1rem !important;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1919px) {
  .hover-view-container {
    row-gap: 1rem !important;
  }
  .hover-view-container .hv-title {
    font-size: 1.5rem !important;
  }
  .hover-view-container .subtitle-info {
    font-size: 0.8rem !important;
  }
  .hover-view-container .sub {
    font-size: 1.2rem !important;
  }
}
@media screen and (min-width: 768px) {
  .grid-prjs-container {
    display: none !important;
  }
  .show-grid-pd {
    display: grid !important;
  }
  .carousel-no-mobile {
    display: unset !important;
  }
  .hide-carousel-pd {
    display: none !important;
  }
}/*# sourceMappingURL=PDProjectSection.css.map */