/*Colors*/
/*Colors Opacity*/
/*Global*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.docs-section {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  -moz-column-gap: 3rem;
       column-gap: 3rem;
  padding: 3rem 0;
  background-image: url("https://pagina-otacc.s3.amazonaws.com/pages/otacc-page/assets/bgs/map-bg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: center;
}
.docs-section .separation-line-mb {
  display: none;
  width: 50%;
}
.docs-section .separation-line {
  height: 10rem;
  border-left: 1px solid black;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .docs-section {
    flex-direction: column;
    row-gap: 3rem;
    padding: 3rem 1rem;
  }
  .docs-section .separation-line-mb {
    display: unset;
  }
  .docs-section .separation-line {
    width: 2rem;
    height: unset;
  }
}/*# sourceMappingURL=DocsFoundationSection.css.map */