/*Colors*/
/*Colors Opacity*/
/*Global*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.counter-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.counter-container .nums-container {
  display: flex;
  color: #ffffff;
  font-size: 1.8rem;
}
.counter-container .counter-subtitle {
  color: #ffffff;
  font-size: 1.1rem;
  text-align: center;
}
.counter-container .show-sign {
  display: unset;
}
.counter-container .hide-sign {
  display: none;
}
.counter-container .add-space {
  margin-left: 1rem;
}

/*Responsive Mobile Small*/
@media screen and (min-width: 320px) and (max-width: 479px) {
  .nums-container {
    font-size: 1rem !important;
  }
  .counter-subtitle {
    font-size: 0.5rem !important;
  }
}
/*Responsive Mobile Standard*/
@media screen and (min-width: 480px) and (max-width: 767px) {
  .nums-container {
    font-size: 1.1rem !important;
  }
  .counter-subtitle {
    font-size: 0.6rem !important;
  }
}/*# sourceMappingURL=Counter.css.map */