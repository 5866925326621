/*Colors*/
/*Colors Opacity*/
/*Global*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.waw-section {
  height: 100%;
}
.waw-section .waw-title-container {
  padding-top: 3rem;
  width: 50%;
  display: flex;
  color: rgb(78, 78, 78);
  align-items: center;
  -moz-column-gap: 0.5rem;
       column-gap: 0.5rem;
}
.waw-section .deco-title {
  width: 1.5rem;
  height: 1rem;
  border: 1px solid grey;
  background-color: grey;
  border-radius: 31px 0px 0px 32px;
}
.waw-section .deco-oposite {
  border: 1px solid #ffffff;
  background-color: #ffffff;
}
.waw-section .second-waw-title {
  color: #ffffff;
}
.waw-section .half-section-container {
  height: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.waw-section .first {
  background-image: url("https://pagina-otacc.s3.amazonaws.com/pages/otacc-page/aboutus/assets/section-first-bg-1.webp");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-bottom: 3rem;
}
.waw-section .second {
  background-image: url("https://pagina-otacc.s3.amazonaws.com/pages/otacc-page/aboutus/assets/section-first-bg-2.webp");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.waw-section .info-first-half-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 2rem;
  width: 50%;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
}
.waw-section .info-first-half-container .info-fh-elm-container {
  display: flex;
  width: 25rem;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
}
.waw-section .info-first-half-container .title-icon {
  width: 2rem;
}
.waw-section .info-first-half-container .num-container {
  font-size: 2rem;
  color: #ffffff;
}
.waw-section .info-first-half-container .text-info-elm-container {
  display: flex;
  flex-direction: column;
  color: rgb(78, 78, 78);
}
.waw-section .info-first-half-container .title-fh-elm-container {
  display: flex;
  align-items: center;
  color: rgb(78, 78, 78);
  -moz-column-gap: 2rem;
       column-gap: 2rem;
}
.waw-section .waw-sh-info-container {
  display: flex;
  flex-direction: column;
  color: #ffffff;
  align-items: center;
  width: 50%;
  margin-top: 3rem;
  row-gap: 3.5rem;
  margin-bottom: 3rem;
}
.waw-section .waw-sh-info-container .subtitle-waw-sh {
  align-self: flex-start;
  color: #ffffff;
}
.waw-section .info-second-half-container {
  height: 50%;
  display: flex;
  justify-content: center;
  -moz-column-gap: 1.5rem;
       column-gap: 1.5rem;
}
.waw-section .info-second-half-container .info-sh-subcontainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: 2rem;
  color: #ffffff;
}
.waw-section .info-second-half-container .info-sh-icon {
  width: 5rem;
  height: 5rem;
}

@media screen and (min-width: 320px) and (max-width: 479px) {
  .waw-section {
    height: unset;
  }
  .waw-section .first {
    padding-bottom: 3rem;
    background-position-x: center;
    background-size: cover;
  }
  .waw-section .second {
    padding-bottom: 3rem;
    background-position-x: center;
    background-size: cover;
  }
  .waw-section .waw-title-container {
    width: unset;
  }
  .waw-section .info-first-half-container {
    flex-direction: column;
    width: 90%;
    row-gap: 1.5rem;
  }
  .waw-section .info-first-half-container .info-fh-elm-container {
    width: unset;
  }
  .waw-section .info-first-half-container .title-icon {
    display: none;
  }
  .waw-section .num-icon {
    width: 3rem;
  }
  .waw-section .waw-sh-info-container {
    width: 90%;
    row-gap: 2rem;
  }
  .waw-section .waw-sh-info-container .info-second-half-container {
    flex-direction: column;
    row-gap: 1.5rem;
  }
  .waw-section .info-sh-subcontainer {
    text-align: center;
  }
}
@media screen and (min-width: 480px) and (max-width: 767px) {
  .waw-section {
    height: unset;
  }
  .waw-section .first {
    padding-bottom: 3rem;
    background-position-x: center;
    background-size: cover;
  }
  .waw-section .second {
    padding-bottom: 3rem;
    background-position-x: center;
    background-size: cover;
  }
  .waw-section .waw-title-container {
    width: unset;
  }
  .waw-section .info-first-half-container {
    flex-direction: column;
    width: 80%;
    row-gap: 1.5rem;
  }
  .waw-section .info-first-half-container .info-fh-elm-container {
    width: unset;
  }
  .waw-section .num-icon {
    width: 3rem;
  }
  .waw-section .waw-sh-info-container {
    width: 80%;
    row-gap: 2rem;
  }
  .waw-section .waw-sh-info-container .info-second-half-container {
    flex-direction: column;
    row-gap: 1.5rem;
  }
  .waw-section .info-sh-subcontainer {
    text-align: center;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .waw-section {
    height: unset;
  }
  .waw-section .first {
    padding-bottom: 3rem;
    background-position-x: center;
    background-position-y: bottom;
    background-size: 125rem;
  }
  .waw-section .second {
    padding-bottom: 3rem;
    background-position-x: center;
    background-size: 125rem;
  }
  .waw-section .waw-title-container {
    width: unset;
  }
  .waw-section .info-first-half-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 90%;
  }
  .waw-section .info-first-half-container .info-fh-elm-container {
    width: unset;
  }
  .waw-section .info-first-half-container .title-icon {
    display: none;
  }
  .waw-section .num-icon {
    width: 3rem;
  }
  .waw-section .waw-sh-info-container {
    width: 90%;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1199px) {
  .waw-section {
    height: unset;
  }
  .waw-section .first {
    padding-bottom: 3rem;
    background-position-x: center;
    background-position-y: bottom;
    background-size: 100rem;
  }
  .waw-section .second {
    padding-bottom: 3rem;
    background-position-x: center;
    background-size: 100rem;
  }
  .waw-section .waw-title-container {
    width: unset;
  }
  .waw-section .info-first-half-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 90%;
  }
  .waw-section .info-first-half-container .info-fh-elm-container {
    width: unset;
  }
  .waw-section .info-first-half-container .title-icon {
    display: none;
  }
  .waw-section .num-icon {
    width: 3rem;
  }
  .waw-section .waw-sh-info-container {
    width: 90%;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1919px) {
  .waw-section .first {
    padding-bottom: 6rem;
    background-position-x: center;
    background-position-y: bottom;
    background-size: cover;
  }
  .waw-section .second {
    background-position-x: center;
    background-size: cover;
  }
  .waw-section .waw-title-container {
    width: 100%;
    margin-left: 10%;
  }
  .waw-section .info-first-half-container {
    width: 90%;
  }
  .waw-section .waw-sh-info-container {
    width: 90%;
  }
  .waw-section .waw-sh-info-container .info-sh-subcontainer {
    margin-bottom: 3rem;
  }
}/*# sourceMappingURL=InfoAboutUsSection.css.map */