@import '../../sass/variables';

.not-found-section {
    height: 100vh;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-image: url('https://pagina-otacc.s3.amazonaws.com/pages/otacc-page/assets/bgs/map-bg.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: center;
    width: 100%;
    .info-err-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        row-gap: 2rem;
        color: $btnColorPrimaryHover;
        h1 {
            font-weight: 200;
            font-size: 2.1rem;
            margin-left: 0.5rem;
        }
        p {
            width: 80%;
            margin-top: 1rem;
            margin-left: 0.5rem;
        }
        .logo-otacc {
            width: 15rem;
        }
    }
}

//Responsive Mobile Small - Mobile Standard
@media screen and (min-width: $mobileSm) and (max-width: calc($tabletSm - 1px)) {
    .not-found-section {
        flex-direction: column;
        padding: 10% 0;
        margin: 15% 0;
        .info-err-container {
            align-items: center;
            margin: 0 10%;
        }
        .img-err-container {
            margin-top: 3rem;
            width: 90%;
            .img-err {
                width: 100%;
            }
        }
    }
}

//Responsive Tablet Small - Tablet Standard
@media screen and (min-width: $tabletSm) and (max-width: calc($screenSm - 1px)) {
    .not-found-section {
        .info-err-container {
            margin-left:  10%;
        }
        .img-err-container {
            margin-right:  10%;
        }
    }
}