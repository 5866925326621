/*Colors*/
/*Colors Opacity*/
/*Global*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.grid-gallery-section {
  padding: 2.5rem 0;
  background-color: #2c2c2c;
  display: flex;
  justify-content: center;
  align-items: center;
}
.grid-gallery-section .fs-gallery-container {
  position: relative;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
}
.grid-gallery-section .fs-gallery-container .reset-value-gallery {
  position: fixed;
  width: 6rem;
  height: 5rem;
  z-index: 9999;
  top: 0;
  right: 0;
  cursor: pointer;
}
.grid-gallery-section .carousel-mobile-hor {
  display: none;
  z-index: 2;
}

/*Responsive Mobile Small*/
@media screen and (min-width: 320px) and (max-width: 479px) {
  .gallery-container {
    display: none !important;
  }
  .reset-value-gallery {
    display: none !important;
  }
  .carousel-mobile-hor {
    display: unset !important;
    height: 18rem;
  }
}
/*Responsive Mobile Standard*/
@media screen and (min-width: 480px) and (max-width: 767px) {
  .gallery-container {
    display: none !important;
  }
  .reset-value-gallery {
    display: none !important;
  }
  .carousel-mobile-hor {
    display: unset !important;
    height: 20rem;
  }
}
/*Responsive Tablet Small*/
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .carousel-mobile-hor {
    display: unset;
  }
}
@media screen and (min-width: 768px) {
  .gallery-hor-section {
    margin: 5.5rem 0 5.5rem 0;
  }
}/*# sourceMappingURL=PDGridGallerySection.css.map */