@import "../../sass/variables";

.projects-section {
  width: 100%;
  .title-section-container {
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 4rem 0;
    .section-title {
      font-size: 1.8rem;
      margin-bottom: 1rem;
    }
  }
  .show-title {
    display: flex;
  }
  .prjs-gallery {
    display: none;
  }
  .open-gallery {
    display: unset;
  }
  .close-gallery {
    width: 5rem;
    height: 5rem;
    z-index: 9999;
    position: fixed;
    top: 0;
    right: 0;
    cursor: pointer;
  }
  .grid-prjs-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .carousel-no-mobile {
    display: none;
  }
  .show-propdevs {
    display: grid;
  }
  .project-container {
    overflow: hidden;
    width: 100%;
    max-height: 50rem;
    position: relative;
    .hover-view-container {
      position: absolute;
      width: 100%;
      height: 0%;
      background-color: $cardHoverOpa;
      z-index: 2;
      opacity: 0;
      transition: all 0.5s ease-in-out;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding-left: 5%;
      row-gap: 2rem;
      .hv-title {
        font-size: 1.8rem;
        color: $basicColorPrimary;
        width: 90%;
      }
      .subtitle-info {
        color: $basicColorPrimary;
        font-size: 1rem;
      }
      .sub {
        font-size: 1.2rem;
      }
      .show-more-btn-container {
        width: 90%;
        position: relative;
        margin-top: 2.5rem;
      }
      .show-gallery-btn {
        position: absolute;
        bottom: -1.2rem;
        right: 2%;
        width: 2.5rem;
        height: 2.5rem;
        font-size: 2rem;
        font-weight: bold;
        border: 1px solid $btnColorSecondary;
        border-radius: 2rem;
        background-color: $basicColorPrimary;
        color: $btnColorSecondary;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        &:hover {
          border-color: $btnColorSecondary;
          background-color: $btnColorSecondary;
          color: $basicColorPrimary;
        }
      }
    }
    &:hover {
      .hover-view-container {
        height: 100%;
        opacity: 1;
      }
    }
    .project-thumbnail {
      width: 100%;
      height: 100%;
    }
    .prop-section {
      filter: grayscale(80%);
      transition: all ease 1s;
      &:hover {
        filter: grayscale(0%);
      }
    }
  }
}

/*Responsive Mobile Small*/

@media screen and (min-width: $mobileSm) and (max-width: calc($mobileStandard - 1px)) {
  .energy-section {
    .project-thumbnail {
      height: 100%;
    }
    .show-gallery-btn {
      width: unset !important;
    }
  }
  .hover-view-container {
    row-gap: 0 !important;
    .hv-title {
      font-size: 0.8rem !important;
    }
    .subtitle-info {
      font-size: 0.5rem !important;
    }
    .sub {
      width: 60% !important;
      font-size: 0.8rem !important;
    }
  }
}

//Responsive Mobile Standard
@media screen and (min-width: $mobileStandard) and (max-width: calc($tabletSm - 1px)) {
  .energy-section {
    .project-thumbnail {
      height: 100%;
    }
    .hv-title {
      font-size: 1rem !important;
    }
    .show-gallery-btn {
      width: unset !important;
    }
  }
  .hover-view-container {
    row-gap: 0 !important;
    .hv-title {
      font-size: 0.8rem !important;
    }
    .subtitle-info {
      font-size: 0.5rem !important;
    }
    .sub {
      width: 60% !important;
      font-size: 0.8rem !important;
    }
  }
}

//Responsive Tablet Small
@media screen and (min-width: $tabletSm) and (max-width: calc($tabletStandard - 1px)) {
  .hv-title {
    font-size: 1rem !important;
  }
  .hover-view-container {
    row-gap: 0 !important;
    .hv-title {
      font-size: 1rem !important;
    }
    .subtitle-info {
      font-size: 0.7rem !important;
    }
    .sub {
      width: 60% !important;
      font-size: 1rem !important;
    }
  }
}

//Responsive Tablet Standard
@media screen and (min-width: $tabletStandard) and (max-width: calc($screenSm - 1px)) {
  .hv-title {
    font-size: 1.2rem !important;
  }
  .hover-view-container {
    row-gap: 0 !important;
    .hv-title {
      font-size: 1.1rem !important;
    }
    .subtitle-info {
      font-size: 0.8rem !important;
    }
    .sub {
      width: 60% !important;
      font-size: 1.1rem !important;
    }
  }
}

//Responsive Screen Small
@media screen and (min-width: $screenSm) and (max-width: calc($screenHd - 1px)) {
  .hover-view-container {
    row-gap: 1rem !important;
    .hv-title {
      font-size: 1.5rem !important;
    }
    .subtitle-info {
      font-size: 0.8rem !important;
    }
    .sub {
      font-size: 1.2rem !important;
    }
  }
}

//Hide Grid Gallery prjs
@media screen and (min-width: $tabletSm) {
  .grid-prjs-container {
    display: none !important;
  }
  .show-grid-pd {
    display: grid !important;
  }
  .carousel-no-mobile {
    display: unset !important;
  }
  .hide-carousel-pd {
    display: none !important;
  }
}
