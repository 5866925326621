@import  '../../sass/variables';

.ene-section-container {
    height: 100vh;
    display: flex;
    justify-content: center;
    height: 100vh;
    scroll-snap-align: start;
    scroll-snap-stop: always;
    object-fit: cover;
    object-position: center;
    position: relative;
    .ene-bg {
        width: 100%;
        height: 100%;
    }
    .ene-btn-container {
        position: absolute;
        bottom: 20%;
        .ene-btn {
            border: 1px solid $basicColorPrimary;
            border-radius: 1rem;
            background-color: $basicColorPrimary;
            color: $btnColorPrimary;
            padding: 0.5rem 2.5rem;
            font-size: 1.2rem;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            &:link {
                color: $btnColorPrimary;
                text-decoration: none;
            }
            &:visited {
                color: $btnColorPrimary;
            }
            &:active {
                color: $btnColorPrimary;
            }
            &:hover {
                background-color: $btnColorPrimary;
                color: $basicColorPrimary;
            }
        }
    }
}

/*Responsive Mobile Small*/

@media screen and (min-width: $mobileSm) and (max-width: calc($mobileStandard - 1px)) {
    .ene-section-container {
        overflow: hidden;
        .ene-bg {
            width: 65rem !important;
        }
        .ene-btn-container {
            margin: 0 5%;
            .ene-btn {
                font-size: 0.8rem;
            }
        }
    }
  }

  /*Responsive Mobile Standard*/

@media screen and (min-width: $mobileStandard) and (max-width: calc($tabletSm - 1px)) {
    .ene-section-container {
        overflow: hidden;
        .ene-bg {
            width: 100rem !important;
        }
        .ene-btn {
            bottom: 5rem;
        }
    }
  }
    
/*Responsive Tablet Small*/

@media screen and (min-width: $tabletSm) and (max-width: calc($tabletStandard - 1px)) {
    .ene-section-container {
        overflow: hidden;
        .ene-bg {
            width: 114rem !important;
        }
    }
  }